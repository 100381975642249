<template>
  <b-container>
    <b-row :key="$route.path">
      <b-col cols=12 tag=h1>
        {{ textAction }}
      </b-col>
      <b-col cols=10 offset=1>
        <b-form
        class="mt-4 d-flex flex-column align-items-end"
        @submit.stop.prevent="createOrUpdateArticle">
          <b-form-input
          type="text"
          v-model="article.title"
          :disabled="isLoading"
          class="mb-3 py-4"
          placeholder="Article Title"/>
          <b-form-input
          type="text"
          :disabled="isLoading"
          v-model="article.description"
          class="mb-3 py-4"
          placeholder="What's this article about?"/>
          <b-form-textarea
          type="text"
          v-model="article.body"
          :disabled="isLoading"
          class="mb-3"
          placeholder="Write your article (in markdown)"/>
          <b-form-tags
          v-model="article.tagList"
          separator=" ,;"
          placeholder="Enter tags"
          class="mb-3"
          :disabled="isLoading"
          no-add-on-enter
          ></b-form-tags>
          <b-button type="submit"
          :disabled="isLoading"
          variant="success">Publish Article</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import store from '@/store'

export default {
  beforeRouteEnter (to, from, next) {
    if (store.getters['user/data']) next()
    else next('/')
  },
  metaInfo () {
    return {
      title: `Editor | test-almocogratis`
    }
  },
  data () {
    return {
      isLoading: false,
      article: {
        title: undefined,
        description: undefined,
        body: undefined,
        tagList: []
      }
    }
  },
  computed: {
    slugArticleForUpdate () {
      const path = this.$route.path.split('/')
      return path[2]
    },
    typeAction () {
      const path = this.$route.path.split('/')
      if (path.length === 2) return 'CREATE_ARTICLE'
      else return 'UPDATE_ARTICLE'
    },
    textAction () {
      if (this.typeAction === 'CREATE_ARTICLE') {
        return 'Create new article'
      } else {
        return 'Update article'
      }
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      async handler () {
        if (this.typeAction === 'UPDATE_ARTICLE') {
          this.isLoading = true
          const username = this.getStore('user/data', 'username')
          this.article = await this.$http.getArticle(this.slugArticleForUpdate)
          .then( ({ data }) => {
            if (data.article.author.username !== username) {
              throw 'USER_IS_NOT_AUTHOR'
            }
            return {
              title: data.article.title,
              description: data.article.description,
              body: data.article.body,
              tagList: data.article.tagList
            }
          }).catch( err => {
            console.error(err)
            this.$router.push({ name: 'Home' })
          }).finally( () => {
            this.isLoading = false
          })
        } else {
          this.clearArticle()
        }
      }
    }
  },
  methods: {
    createOrUpdateArticle () {
      if (this.typeAction === 'CREATE_ARTICLE') {
        this.createArticle()
      }
      if (this.typeAction === 'UPDATE_ARTICLE') {
        this.updateArticle()
      }
    },
    clearArticle () {
      this.article = {
        title: undefined,
        description: undefined,
        body: undefined,
        tagList: []
      }
    },
    async createArticle () {
      this.isLoading = true
      await this.$http.createArticle({
        article: {
          title: this.article.title,
          description: this.article.description,
          body: this.article.body,
          tagList: this.article.tagList,
        }
      }).then( ({ data }) => {
        this.clearArticle()
        this.$router.push(`/article/${data.article.slug}`)
      }).finally( () => {
        this.isLoading = false
      })
    },
    async updateArticle () {
      this.isLoading = true
      await this.$http.updateArticle(this.slugArticleForUpdate, {
        article: {
          ...this.article,
          updateAt: undefined
        }
      }).finally( () => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss">
  textarea {
    min-height: 200px;
  }
</style>